<template>
  <section class="content-container">
    <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>

<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
  components: { 
      SectionTabs, 
    },
  data() {
    return {
      title: "Videos",
      tab_component: [
        {
          name: "Inicio",
          route: "videos-list",
        },
        {
          name: "Agregar",
          route: "videos-add",
        },
        {
          name: "Categorías",
          route: "videos-category",
        },
      ],
    };
  },
  methods:{
    checkRoute(){
      let tab_edit = {
        name: "Editar",
        route: "videos-edit",
      }
      let tab_add = {
        name: "Agregar",
        route: "videos-add",
      }
      if(this.$route.name == "videos-edit"){
        Object.assign(this.tab_component[1], tab_edit);
      }else{
        Object.assign(this.tab_component[1], tab_add);
      }
    }
  },
  mounted() {
    this.checkRoute()
  },
  updated(){
    this.checkRoute();
  }
};
</script>
